import { defaultTrackingAttributes, globalState } from "./utils";
import { CLICK, VIEW, ACTIVITY_LIST } from "./constants";

export function activityTabFiltersViewTrackingAttributes() {
  return {
    page: ACTIVITY_LIST,
    event: VIEW,
    context: "ActivityTabFiltersView",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function activityTabFiltersClickTrackingAttributes(value: string[]) {
  return {
    page: ACTIVITY_LIST,
    event: CLICK,
    context: "ActivityTabFiltersClick",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
      value,
    },
  };
}

export default {
  activityTabFiltersViewTrackingAttributes,
  activityTabFiltersClickTrackingAttributes,
};
