import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { string, object } from "yup";
import { styled, useTheme } from "@mui/material/styles";
import { lighten } from "polished";
import RegistrationImage from "@assets/images/registrationImage.png";
import { sendOrganicMagicLink } from "@services/usersService";
import { CR_TOS_URL, CR_PRIVACY_URL } from "@constants/pathConstants";
import MaterialTextField from "../atoms/material/MaterialTextField";
import MaterialButton from "../atoms/material/MaterialButton";
import ConstrictedModal from "../molecules/ConstrictedModal";
import useWindowSize from "../../hooks/useWindowSize";
import ArrowDown from "../../assets/icons/ArrowDown";
import ArrowRight from "../../assets/icons/ArrowRight";
import Img from "../atoms/Img";
import Spacing from "../layouts/Spacing";
import { media } from "../../utils/style";
import Typography from "../atoms/material/MaterialTypography";
import { zIndexUnderlinedLink } from "../../constants/zIndexConstants";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import {
  landingCreateAccountTrackingAttributes,
  landingCreateAccountSendLinkButtonTrackingAttributes,
} from "../../analytics";

const UnderlinedLink = styled("a")`
  text-decoration: underline !important;
  z-index: ${zIndexUnderlinedLink};
`;

const TermsOfServiceLabel = (
  <Typography variant="caption">
    By clicking &apos;Continue&apos; you agree to the&nbsp;
    <UnderlinedLink rel="noopener noreferrer" target="_blank" href={CR_PRIVACY_URL} about="_blank">
      privacy policy
    </UnderlinedLink>
    &nbsp;and&nbsp;
    {/* This is not in the context of a property so we can't use loyalty tos */}
    <UnderlinedLink rel="noopener noreferrer" target="_blank" href={CR_TOS_URL} about="_blank">
      terms of service
    </UnderlinedLink>
    .
  </Typography>
);

const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${media.mobile`
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  `}
`;

const BodyWrapper = styled("div")`
  position: relative;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  ${media.mobile`
    margin-top: 0px;
  `}
`;

const BottomWrapper = styled("div")`
  position: absolute;
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom) - 16px);
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${(props) => lighten(0.05, props.backgroundColor || "#00A0DF")};
  }
`;

const TopSectionWrapper = styled("section")`
  height: 40%;
`;

const BottomSectionWrapper = styled("section")`
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const FormWrapper = styled("form")`
  padding: 24px;
  width: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

const initialFormValues = {
  email: "",
  tosAccepted: false,
};

const createAccountSchema = object({
  email: string().email().required(),
});

export default function NewUserRegistration({ programAssets }) {
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const tracking = useAuthenticationTracking();

  useEffect(() => {
    tracking.trackEvent(landingCreateAccountTrackingAttributes());
  }, []);

  const onSubmit = async (params) => {
    try {
      sendOrganicMagicLink(params);
      tracking.trackEvent(landingCreateAccountSendLinkButtonTrackingAttributes());
      history.push("/link-sent", { email: params.email });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.err(e);
    }
  };

  const formik = useFormik({
    onSubmit,
    initialValues: initialFormValues,
    validationSchema: createAccountSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const crText = `What is ${programAssets.name}?`;

  return (
    <PageWrapper>
      <BodyWrapper>
        {!isMobile && <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />}
        <ConstrictedModal>
          <ConstrictedModal.ModalContainer>
            <ConstrictedModal.ModalLeft>
              <Spacing
                paddingLeft="32px"
                paddingRight="32px"
                width="auto"
                height="100%"
                display="flex"
                alignItems="center"
              >
                <img src={RegistrationImage} width="100%" alt="Register!" />
              </Spacing>
            </ConstrictedModal.ModalLeft>
            <ConstrictedModal.ModalRight>
              <FormWrapper noValidate onSubmit={formik.handleSubmit}>
                {isMobile && (
                  <Spacing width="auto" display="flex" flexDirection="column" justifyContent="center" height="220">
                    <Img alt="Register!" src={RegistrationImage} width="auto" height="100%" />
                  </Spacing>
                )}
                <TopSectionWrapper>
                  <Typography variant="h6" align="center">
                    Welcome to your community&apos;s loyalty rewards program
                  </Typography>
                  <br />
                  <Typography variant="body1" align="center">
                    Login with your email to earn rewards and stay connected to what&apos;s happening at your community.
                  </Typography>
                </TopSectionWrapper>
                <BottomSectionWrapper>
                  <Typography variant="body2" align="center">
                    Enter the same email your property has on file to get started.
                  </Typography>
                  <br />
                  <MaterialTextField
                    name="email"
                    variant="filled"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    label={formik.errors.email ? formik.errors.email : "Enter Email"}
                    placeholder="Email Address"
                    error={!!formik.errors.email}
                    type="email"
                    required
                    fullWidth
                  />
                  <Spacing height="16" />
                  {TermsOfServiceLabel}
                  <br />
                  <MaterialButton type="submit" color="secondary" fullWidth>
                    Continue
                  </MaterialButton>
                  {isMobile && <Spacing height="50px" />}
                </BottomSectionWrapper>
              </FormWrapper>
            </ConstrictedModal.ModalRight>
          </ConstrictedModal.ModalContainer>
        </ConstrictedModal>
      </BodyWrapper>
      <Link to="/value" href="/value">
        <BottomWrapper backgroundColor={theme.palette.secondary.main}>
          <Typography color="contrastPrimary">{crText}</Typography>
          &nbsp;
          {isMobile ? (
            <ArrowRight fill={theme.palette.common.white} />
          ) : (
            <ArrowDown fill={theme.palette.common.white} />
          )}
        </BottomWrapper>
      </Link>
    </PageWrapper>
  );
}

NewUserRegistration.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
  }).isRequired,
};
