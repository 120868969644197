// Color Variants are built off the design language system documented here:
// https://slick.zeroheight.com/
// https://www.notion.so/modernmessage/a1e871acb3b84a668cc6f05f81ca6bdf

export const DEFAULT_COLOR_VARIANTS = {
  textPrimary: "#0A1637",
  textSecondary: "#767676",
  primary: "#002856",
  secondary: "#00A0DF",
  alert: "#FF3D7F",
  urgent: "#FBC423",
  success: "#00823B",
  warning: "#FB8B23",
  warningCta: "#FB8B23",
  error: "#D01A1f",
  background: "#F2F4F6",
  lightGrey: "#C1CDD9",
  lightestGray: "#F9F7F7",
  mediumGrey: "#7E8E9F",
  darkGrey: "#5f6b78",
  crGreen: "#83C91F",
  rpOrange: "#f06000",
  modernBlack: "#0A1637", // separate from textPrimary to avoid white label override
  blueGray: "#C4D5DC",
  lightBlueGray: "#E4F1F2",
  warmYellow: "#F1D3BE",
  lightYellow: "#FDF0D4",
  purpleGray: "#BFBDCA",
  lightPurpleGray: "#E6E8EF",
  amber: "#BC8701",
  infoColor: "#0076CC",
} as const;

export const CR_LITE_COLOR_VARIANTS = {
  ...DEFAULT_COLOR_VARIANTS,
  primary: "#202737",
  secondary: "#3D7F8D",
  error: "#D01A1f",
  alert: "#f06000",
  success: "#118837",
  warning: "#fec12d",
} as const;

export const LOYALTY_COLORS_VARIANTS = {
  ...DEFAULT_COLOR_VARIANTS,
  textPrimary: "#282829",
  primary: "#282829",
} as const;

export const LOFT_COLORS_VARIANTS = {
  primary: "#282829",
  secondary: "#282829",
  iris: "#B1B1F3",
  grill: "#F7B153",
  blush: "#FAB1F3",
  sand: "#F0E9E0",
  silver: "#C5CEF8",
  silverGaugeActive: "#8898EA",
  silverGaugeBackground: "#274396",
  gold: "#FDEBAF",
  goldGaugeActive: "#EFC84D",
  goldGaugeBackground: "#B0780D",
  platinum: "#B8D7E7",
  platinumGaugeActive: "#71B0CE",
  platinumGaugeBackground: "#2A3850",
  darkGray: "#A2AAB2",
  darkGrey: "#A2AAB2",
  mediumGray: "#C7CCD0",
  mediumGrey: "#C7CCD0",
  lightGray: "#E9EAEB",
  lightestGray: "#F9F7F7",
  background: "#E9EAEB",
  lightGrey: "#E9EAEB",
  error: "#D01A1F",
  alert: "#D01A1F",
  success: "#118837",
  warning: "#FEC12D",
  warningCta: "#BC8701",
  info: "#0076CC",
} as const;

/**
 * Colors - these colors should be used as little as possible, and only where
 * colors from the material theme object cannot be used.
 */
export const COLORS = {
  binary: "#FFFFFF",
  white: "#F4F4F4",
  gray: "#929292",
  navGrey: "#ebedee",
  green: "#6CBF8B",
  blue: "#127CC3",
  orange: "#E89C30",
  lookerBackground: "#F6F8FA",
  whisper: "#EEE",
  cerulean: "#01A0DE",
  mistyHarborBlue: "#6C96A8",
  sunsetGold: "#E5A268",
  midnightPlum: "#5D5879",
  darkCerulean: "#244F59",
  lightSky: "#BFDDE1",
  darkWood: "#975F36",
  lightOrange: "#FADD95",
  sandyTan: "#DF945D",
  deepPurple: "#180536",
  coolSilver: "#C5CADA",
  slatePurple: "#5D5879",
  whisperWhite: "#fefefe",
  lightCyan: "#DAF6F1",
  lightSteel: "#C1D3D8",
  peachPuff: "#FFF2E5",
  peachCream: "#FFD0C0",
  gentleSky: "#DBE3FA",
  coolLavender: "#ABA8BB",
  lightGray: "#f4f4f4",
  athensGray: "#F5F6F8",
} as const;

/**
 * Residents sentiment colors used for sentiment score graph and resident tables.
 */
export const SENTIMENT_COLORS = Object.freeze({
  tableNeutral: "#BC8701",
  tablePositive: "#139C3E",
  tableNegative: "#D01A1F",
  graphNeutral: "#EBBD46",
  graphPositive: "#66BE50",
  graphNegative: "#DE4841",
} as const);

export default {
  COLORS,
  DEFAULT_COLOR_VARIANTS,
  SENTIMENT_COLORS,
  CR_LITE_COLOR_VARIANTS,
  LOYALTY_COLORS_VARIANTS,
  LOFT_COLORS_VARIANTS,
};
