import { defaultAmenifyData, defaultTrackingAttributes, productData } from "./utils";
import { CLICK, VIEW, EARN } from "./constants";

export function amenifySurveyCardShownTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: VIEW,
    context: "AmenifySurveyCardShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveyCardClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveyCardClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveyAsideShownTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: VIEW,
    context: "AmenifySurveyAsideShown",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveyOptInClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveyOptInClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveyExploreClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveyExploreClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}

export function amenifySurveyBenefitsClickTrackingAttributes(config, amenifyOptedIn) {
  return {
    page: EARN,
    event: CLICK,
    context: "AmenifySurveyBenefitsClick",
    ...defaultTrackingAttributes(),
    data: {
      ...productData(),
      ...defaultAmenifyData(),
      actionConfigTitle: config.title,
      actionConfigId: config.id,
      optIn: amenifyOptedIn,
    },
  };
}
