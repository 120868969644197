import { captureMessage } from "@sentry/browser";
import clone from "ramda/src/clone";
import ahoy from "ahoy.js";
import { isAlloyApp } from "@utils/isMobile";
import { idCleaner } from "./utils";
import { PAGE_LOAD, MAIN_APP } from "./constants";

/**
 * This is temporary code introduced in order to send tracking data to Ahoy that we need for perks.
 * Remove when Radix is deployed to production.
 * */
function sendToAhoy(data, events, trackedData) {
  if (events.includes(data?.context) || events === "*") {
    ahoy.track(data.context, trackedData);
  }
}

/**
 * Function that determines which app data is coming from.
 * This is currently used to differentiate between ActiveBuilding (Alloy)
 * and Default (non-AB/non-Alloy) traffic in Google Analytics.
 */
function appNameForGoogleAnalytics() {
  if (isAlloyApp()) {
    return "ActiveBuilding";
  }
  return "Default";
}

function sendData(data) {
  const clonedData = clone(data);
  idCleaner(clonedData);

  if (ahoy) {
    switch (clonedData.page) {
      case "TabBar":
        sendToAhoy(
          clonedData,
          [
            "EarnTabView",
            "RedeemTabView",
            "BenefitsTabView",
            "ActivityTabView",
            "EarnTabClick",
            "RedeemTabClick",
            "BenefitsTabClick",
            "ActivityTabClick",
          ],
          { ...clonedData }
        );
        break;
      case "portfolio-dashboard-view":
        sendToAhoy(
          {
            context: "PORTFOLIO_DASHBOARD_PAGE",
          },
          "*",
          { ...clonedData }
        );
        break;
      case "DashboardFeedbackPage":
        sendToAhoy(
          clonedData,
          ["SubmissionCardCtaClick", "ShowSuggestionsFilterClick", "ShowMonthlyReportLinkClick", "DownloadFeedbackCSV"],
          { ...clonedData.data }
        );
        break;
      case "DashboardEngagePostsPage":
        sendToAhoy(
          clonedData,
          [
            "DashboardEngagePostsActionsPage",
            "DashboardEngagePostsPollsPage",
            "DashboardEngagePostsEventsPage",
            "DashboardEngagePostsAnnouncementsPage",
          ],
          { ...clonedData.data }
        );
        break;
      case "DashboardReviewsPage":
        sendToAhoy(clonedData, ["OraCardCtaClick", "UnrepliedCardCtaClick", "ReviewStatsCtaClick"], {
          ...clonedData.data,
        });
        break;
      case "Perks":
        sendToAhoy(
          clonedData,
          [
            "PerksDetailView",
            "PerksListItemClick",
            "PerksDetailRedeemConfirmationButtonClick",
            "PerksDetailRedeemView",
          ],
          { perkId: clonedData?.data?.perkId }
        );
        break;
      case "SSOTOSLanding":
        sendToAhoy(clonedData, ["SSOTOSLanding-pageLoad", "SSOTOSLanding-ContinueButton-click"], {
          ...clonedData.data,
        });
        break;
      case "ActionsList":
        sendToAhoy(clonedData, ["ActionsListAlbumTeaserWidget", "ActionsListItemComplete"], { ...clonedData.data });
        break;
      case "Redeem":
        sendToAhoy(
          clonedData,
          [
            "SweepstakesFakeDoorShown",
            "SweepstakesFakeDoorClick",
            "SweepstakesFakeDoorAsideShown",
            "SweepstakesWaitListClick",
          ],
          { ...clonedData.data }
        );
        break;
      case "Benefits":
        sendToAhoy(
          clonedData,
          [
            "AmenifyFakeDoorShown",
            "AmenifyFakeDoorClick",
            "AmenifyFakeDoorAsideShown",
            "TravelFakeDoorShown",
            "TravelFakeDoorClick",
            "TravelFakeDoorAsideShown",
            "AmenifyWaitListClick",
            "TravelWaitListClick",
            "LifeStyleCardShown",
            "LifeStyleCardClick",
            "LifeStyleAsideShown",
            "LifeStyleOptInClick",
            "LifeStyleExploreClick",
          ],
          { ...clonedData.data }
        );
        break;
      case "ActivityList":
        sendToAhoy(clonedData, ["ActivityTabFiltersView", "ActivityTabFiltersClick"], { ...clonedData.data });
        break;
      case "Earn":
        sendToAhoy(
          clonedData,
          [
            "AmenifySurveyCardShown",
            "AmenifySurveyCardClick",
            "AmenifySurveyAsideShown",
            "AmenifySurveyOptInClick",
            "AmenifySurveyExploreClick",
            "AmenifySurveyBenefitsClick",
          ],
          {
            ...clonedData.data,
          }
        );
        break;
      default:
        sendToAhoy(clonedData, [], {});
    }
  }

  if (window.Appcues) {
    try {
      if (data.event === PAGE_LOAD) {
        if (data.page !== MAIN_APP) {
          /**
           * filter out main app page views since this isn't a real page
           * and fires twice for some reason
           */
          window.Appcues.page();
        }
      } else {
        window.Appcues.track(data?.context, {
          eventCategory: data?.event,
          eventAction: data?.context,
        });
      }
    } catch (e) {
      const name = e.name || "";
      const message = e.message || "An appcues Analytics error has occured";
      captureMessage(`${name}: ${message}`);
    }
  }

  if (window.gtag) {
    try {
      // Find the GA4 Measurement ID if it exists
      let measurementId;
      const scripts = document.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i += 1) {
        const scriptSrc = scripts[i].getAttribute("src");
        if (scriptSrc && scriptSrc.includes("gtag/js")) {
          const matches = scriptSrc.match(/id=([A-Z0-9-]+)/i);
          if (matches && matches.length > 1) {
            measurementId = matches[1]; // eslint-disable-line prefer-destructuring
            break;
          }
        }
      }

      if (data.event === PAGE_LOAD) {
        if (data.page !== MAIN_APP) {
          /**
           * filter out main app page views since this isn't a real page
           * and fires twice for some reason
           */
          // Set page path aka name
          window.gtag("config", measurementId, {
            page_path: data.page,
          });
          // Page View
          window.gtag("event", "page_view", {
            send_to: measurementId,
            page_path: data?.page,
            app_name: `${appNameForGoogleAnalytics()}`,
          });
        }
      } else {
        window.gtag("event", data?.event, {
          event_category: data?.event,
          event_action: data?.context,
          page_path: data?.page,
          app_name: appNameForGoogleAnalytics(),
        });
      }
    } catch (e) {
      const name = e.name || "";
      const message = e.message || "An undefined Google Analytics error has occurred";
      captureMessage(`${name}: ${message}`);
    }
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(clonedData);
}

/**
 * Function which dictates how to process tracking data passed to <MainApp />
 * @param {*} data from react-tracker
 */
export function processRewardsTrackingData(data) {
  sendData(data);
}

/**
 * Function which dictates how to process tracking data passed to any Dashboard Entry Point
 * @param {*} data from react-tracker
 */
export function processDashboardTrackingData(data) {
  sendData(data);
}

export function processAuthenticationTrackingData(data) {
  sendData(data);
}

export function processTabNavigationData(data) {
  sendData(data);
}

/**
 * Function which dictates how to process tracking data passed to SSO / Integration components
 * Doesn't do anything different than the other functions for now...
 * @param {*} data from react-tracker
 */
export function processSSOTOSLandingTrackingData(data) {
  sendData(data);
}

export function processBenefitsData(data) {
  sendData(data);
}

export function processActivityListData(data) {
  sendData(data);
}

export function processSweepstakesData(data) {
  sendData(data);
}

export * from "./mainAppAnalytics";
export * from "./communityGuideAnalytics";
export * from "./authenticationAnalytics";
export * from "./benefitsAnalytics";
export * from "./amenifySurveyAnalytics";
export * from "./tabNavigationAnalytics";
export * from "./activityListAnalytics";

export default {
  processRewardsTrackingData,
  processAuthenticationTrackingData,
  processDashboardTrackingData,
  processSSOTOSLandingTrackingData,
  processBenefitsData,
  processTabNavigationData,
  processActivityListData,
  processSweepstakesData,
};
