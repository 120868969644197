import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import getCookie from "@utils/getCookie";
import GlobalStyle, { primaryFontFamily, secondaryFontFamily } from "./GlobalStyle";
import {
  DEFAULT_COLOR_VARIANTS,
  CR_LITE_COLOR_VARIANTS,
  LOFT_COLORS_VARIANTS,
  LOYALTY_COLORS_VARIANTS,
} from "./colors";
import { DEVICE_SIZES } from "../constants/styleConstants";

const createCustomTheme = (propWhiteLabel, colorVariants, globalWhiteLabel) => {
  const formatHexColor = (color) => `#${color}`;
  const mainThemeColor = (color, name) => {
    if (!color) return colorVariants[name];
    if (globalWhiteLabel[name]) return formatHexColor(globalWhiteLabel[name]);
    return propWhiteLabel?.enabled ? formatHexColor(color) : colorVariants[name];
  };

  const defaultTheme = createTheme();

  return createTheme({
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,
        md: DEVICE_SIZES.mobile,
        lg: DEVICE_SIZES.desktop,
      },
    },
    typography: {
      fontFamily: primaryFontFamily,
      useNextVariants: true,
      h2: { fontFamily: secondaryFontFamily, fontWeight: "bold", fontSize: "3rem" },
      h3: { fontFamily: secondaryFontFamily, fontWeight: "bold", fontSize: "2.625rem" },
      h4: { fontFamily: secondaryFontFamily, fontWeight: "900", fontSize: "2rem" },
      h5: { fontFamily: secondaryFontFamily, fontWeight: "900", fontSize: "1.5rem" },
      h6: { fontFamily: secondaryFontFamily, fontWeight: "900", fontSize: "1.25rem" },
      subtitle1: { fontWeight: "700", fontSize: "1rem" },
      subtitle2: { fontWeight: "700", fontSize: ".875rem" },
      body1: { fontWeight: "400", fontSize: ".875rem" },
      body2: { fontWeight: "700", fontSize: ".75rem" },
      caption: { fontWeight: "400", fontSize: ".75rem" },
      button: { fontWeight: "700", fontSize: ".875rem" },
      overline: { fontWeight: "700", fontSize: ".75rem" },
      srOnly: { fontWeight: "700", fontSize: "1rem" },
      inherit: { fontWeight: "700", fontSize: "1rem" },
    },
    palette: {
      primary: { main: mainThemeColor(propWhiteLabel?.primaryColor, "primary") },
      secondary: { main: mainThemeColor(propWhiteLabel?.secondaryColor, "secondary"), contrastText: "#FFF" },
      error: { main: colorVariants?.error || mainThemeColor(propWhiteLabel?.alertColor, "alert") },
      text: { primary: colorVariants.textPrimary, secondary: colorVariants.textSecondary },
      alert: { main: mainThemeColor(propWhiteLabel?.alertColor, "alert") },
      urgent: { main: mainThemeColor(propWhiteLabel?.urgentColor, "urgent") },
      success: { main: mainThemeColor(propWhiteLabel?.successColor, "success") },
      amber: { main: mainThemeColor(propWhiteLabel?.amberColor, "amber") },
      warning: { main: mainThemeColor(propWhiteLabel?.warningColor, "warning") },
      warningCta: { main: colorVariants.warningCta },
      backgroundColor: { main: mainThemeColor(propWhiteLabel?.backgroundColor, "background") },
      mediumGrey: { main: mainThemeColor(propWhiteLabel?.mediumGreyColor, "mediumGrey") },
      lightGrey: { main: mainThemeColor(propWhiteLabel?.lightGreyColor, "lightGrey") },
      lightestGray: { main: colorVariants.lightestGray },
      crGreen: { main: mainThemeColor(propWhiteLabel?.crGreenColor, "crGreen") },
      darkGrey: { main: mainThemeColor(propWhiteLabel?.darkGreyColor, "darkGrey") },
      rpOrange: { main: mainThemeColor(propWhiteLabel?.rpOrangeColor, "rpOrange") },
      modernBlack: { main: mainThemeColor(propWhiteLabel?.modernBlackColor, "modernBlack") },
      blueGray: {
        main: mainThemeColor(propWhiteLabel?.blueGrayColor, "blueGray"),
        secondary: mainThemeColor(propWhiteLabel?.blueGrayColor, "lightBlueGray"),
      },
      purpleGray: {
        main: mainThemeColor(propWhiteLabel?.purpleGrayColor, "purpleGray"),
        secondary: mainThemeColor(propWhiteLabel?.purpleGrayColor, "lightPurpleGray"),
      },
      warmYellow: {
        main: mainThemeColor(propWhiteLabel?.warmYellowColor, "warmYellow"),
        secondary: mainThemeColor(propWhiteLabel?.warmYellowColor, "lightYellow"),
      },
      crLiteBackground: { main: "#FFF" },
      infoColor: { main: colorVariants.infoColor },
    },
  });
};

const ThemeWrapper = (props) => {
  const { crLite, loyalty } = props.property;
  const loftFlag = getCookie("new_loft") === "new" || props.loft;
  const globalWhiteLabel = {
    primary: global?.window?.white_label_primary || false,
    secondary: global?.window?.white_label_secondary || false,
  };

  let colorVariants = DEFAULT_COLOR_VARIANTS;
  if (crLite) colorVariants = CR_LITE_COLOR_VARIANTS;
  if (loyalty) colorVariants = LOYALTY_COLORS_VARIANTS;
  if (loftFlag)
    colorVariants =
      loyalty || props.loft ? { ...LOYALTY_COLORS_VARIANTS, ...LOFT_COLORS_VARIANTS } : { ...DEFAULT_COLOR_VARIANTS };

  const { whiteLabel } = props;
  const theme = useMemo(
    () => createCustomTheme(whiteLabel, colorVariants, globalWhiteLabel),
    [whiteLabel, colorVariants, globalWhiteLabel]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle crlite={crLite} />
          {props.children}
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeWrapper.propTypes = {
  children: PropTypes.node,
  loft: PropTypes.bool,
  property: PropTypes.shape({
    crLite: PropTypes.bool,
    loyalty: PropTypes.bool,
  }),
  whiteLabel: PropTypes.shape({
    enabled: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    alertColor: PropTypes.string,
    urgentColor: PropTypes.string,
    successColor: PropTypes.string,
    amberColor: PropTypes.string,
    warningColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    mediumGreyColor: PropTypes.string,
    lightGreyColor: PropTypes.string,
    crGreenColor: PropTypes.string,
    darkGreyColor: PropTypes.string,
    rpOrangeColor: PropTypes.string,
    modernBlackColor: PropTypes.string,
    blueGrayColor: PropTypes.string,
    warmYellowColor: PropTypes.string,
    purpleGrayColor: PropTypes.string,
    infoColor: PropTypes.string,
  }),
};

ThemeWrapper.defaultProps = {
  children: null,
  property: {
    crLite: false,
    loyalty: false,
  },
  whiteLabel: {},
};

export default ThemeWrapper;
export const ThemeWrapperForStorybook = ThemeWrapper;
